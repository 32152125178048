.style__option__cU-Qz {
  text-align: left;
  transition: background-color 200ms;
  padding-left: 5px;
  border-radius: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.style__option-active__Vj611 {
  background: #3d3d3d;
  color: #fff;
  font-weight: bold;
  pointer-events: none;
  cursor: pointer;
}
.style__option-label__pAf3w {
  height: 100%;
  display: flex;
  align-items: center;
  color: #666666;
}
.style__option__cU-Qz:not(.style__option-active__Vj611):not(.style__option-label__pAf3w):hover {
  background: #f2f2f2;
}
.style__option-divider__wH0tP {
  padding-bottom: 5px;
  border-bottom: 1px solid #cccccc;
}
.style__option-spacer__15Giq {
  padding-bottom: 5px;
}
.style__option-text__e7qJ4 {
  height: 100%;
  display: flex;
  align-items: center;
  color: #000000;
  padding-left: 5px;
}

.style__no-option__IMDrS {
  padding: 13px 20px 0px 25px;
}

.style__thumbnail-container__HH4Yk {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
}

.style__name-container__W5bit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  flex: 1;
  overflow: hidden;
}
.style__name-container__W5bit span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.style__email__zyYZ1 {
  color: #727272;
}