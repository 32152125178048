.CheckCSRF-style__overlay__iKnEI {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.CheckCSRF-style__dialog__FnZNE {
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  margin: 0 40px;
  max-width: 300px;
}
.CheckCSRF-style__dialog__FnZNE h1 {
  margin-top: 0;
}