.style__container__gOOMN {
  display: flex;
  justify-content: space-around;
  width: 85%;
  margin: 0 auto 20px auto;
}
.style__container__gOOMN ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.style__containerVertical__A5mIz {
  text-align: center;
}
.style__containerVertical__A5mIz li {
  margin-bottom: 15px;
  width: 100%;
}
.style__containerVertical__A5mIz .style__commit__UDA2F {
  width: 100%;
}

.style__button-group-button__X7VHN {
  padding-top: 7px;
  padding-bottom: 7px;
  color: #fff;
  border-radius: 3px;
  min-width: 50%;
  margin: 24px 5px 0px 5px;
}

.style__commit__UDA2F {
  background: #3576ba;
}
.style__commit__UDA2F:active {
  background: #4c649d;
  outline: none;
}
.style__commit__UDA2F:hover {
  background: #6097d2;
}
.style__commit__UDA2F:focus {
  background: #3576ba;
}

.style__cancel__6c-EP {
  background: #eeeeee;
  color: #444444;
}
.style__cancel__6c-EP:active {
  background: #c0c0c0;
}
.style__cancel__6c-EP:hover {
  background: #e0e0e0;
}
.style__cancel__6c-EP:focus {
  background: #eeeeee;
}

.style__danger__lG6Z4 {
  background: #cc2e3b;
}
.style__danger__lG6Z4:active {
  background: #a62833;
}
.style__danger__lG6Z4:hover {
  background: #db656f;
}
.style__danger__lG6Z4:disabled {
  background: #edbec2;
}

.style__top-border__V8tm3 {
  border-top: 1px solid #ddd;
  padding-top: 25px;
  margin-top: 20px;
}

.style__vertical__cn8Cx {
  flex-direction: column-reverse;
  align-items: stretch;
  padding-top: 16px;
  gap: 8px;
}
.style__vertical__cn8Cx .style__button-group-button__X7VHN {
  width: auto;
  margin-top: 0;
}