.style__icon__PwlW3 {
  height: 8px;
  width: 8px;
  fill: #cccccc;
}

.style__option__z-lmi {
  text-align: left;
  font-weight: normal;
  transition: background-color 200ms;
  border-radius: 2px;
  display: flex;
  padding-left: 12px;
  padding-right: 12px;
}
.style__option-current__giIZB {
  font-weight: bold;
}
.style__option-label__sKsXb {
  height: 100%;
  display: flex;
  align-items: center;
  color: #808080;
}
.style__option__z-lmi:not(.style__option-current__giIZB):not(.style__option-label__sKsXb):hover {
  background: #f2f2f2;
}

.style__organization-name__zVdGe {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.style__thumbnail__-Y4z8 {
  border-radius: 2px;
  align-self: center;
}

.style__user-name__7hJbk {
  align-self: center;
  padding-left: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.style__fixed-label__HxlNi {
  padding: 12px;
}

.style__divider__nCwSs {
  border-top: 1px solid #cccccc;
  padding-bottom: 6px;
  width: 100%;
}

.style__list-wrapper__t6Fqb {
  height: 100%;
  width: 100%;
}
.style__list-wrapper__t6Fqb div {
  margin: 0px;
}