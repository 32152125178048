.checkbox__wrapper__ZJmj2 {
  display: flex;
  height: 35px;
}

.checkbox__double-wrapper__Ojnu0 {
  display: flex;
  height: 70px;
}
.checkbox__wrapper-with-hover__-UnfO:hover {
  background: #eaeaea;
}

.checkbox__wrapper-active__c8Xn9 {
  background: #eaeaea;
}

.checkbox__checkbox__n4kgy {
  flex: 0 0 32px;
  cursor: pointer;
}
.checkbox__checkbox__n4kgy:hover {
  background: #eaeaea;
}

.checkbox__divider__BtqTK {
  flex: 0 0 1px;
  background: #e4e4e4;
}

.checkbox__label__uIOf- {
  flex-grow: 1;
  padding: 8px 8px 8px 8px;
  color: black;
  text-align: left;
  overflow: hidden;
  cursor: pointer;
}
.checkbox__label__uIOf-:hover {
  background: #eaeaea;
}
.checkbox__label__uIOf-:not(.checkbox__double-line__fT8-Q) {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.checkbox__labelWithInfoIcon__CTmrn {
  flex-grow: 1;
  width: 90%;
  padding: 8px 8px 8px 8px;
  color: black;
  text-align: left;
  overflow: hidden;
  cursor: pointer;
}
.checkbox__labelWithInfoIcon__CTmrn:hover {
  background: #eaeaea;
}
.checkbox__labelWithInfoIcon__CTmrn:not(.checkbox__double-line__fT8-Q) {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.checkbox__info__PMz6z {
  flex-grow: 1;
  width: 10%;
  padding: 8px 8px 8px 8px;
  cursor: default;
}