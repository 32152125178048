.style__wrap__k-2sj {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.style__content__5Ptw4 {
  overflow: auto;
  padding: 24px;
}

.style__controls__5mMke {
  border-top: 1px solid #dfdfdf;
  padding: 0 24px;
}
.style__controls__5mMke button {
  width: 132px;
}

.style__icon__Qc-Jq {
  width: 24px;
  height: 24px;
  border-radius: 3px;
  display: -webkit-inline-box;
  margin-left: 4px;
}

.style__at-risk-icon__PXbVJ {
  width: 30px;
  height: 30px;
  background-color: rgb(243, 183, 0);
  border-radius: 3px;
  display: -webkit-inline-box;
}

.style__blue-bright-icon__ENQsE {
  background-color: #92d4f4;
}

.style__blue-dark-icon__t65pG {
  background-color: #24a2eb;
}

.style__green-bright-icon__QFX-z {
  background-color: #00bf64;
}

.style__green-dark-icon__K-aIL {
  background-color: #007c3f;
}

.style__yellow-icon__6l8Ka {
  background-color: #fac905;
}

.style__orange-icon__7g5v8 {
  background-color: #ff8533;
}

.style__red-icon__2alPO {
  background-color: #e81a56;
}

.style__purple-icon__FxHrk {
  background-color: #c285ff;
}

.style__internal-purple-icon__Hx91T {
  background-color: #cd89f0;
}

.style__dark-grey-icon__m5T7U {
  background-color: #5c5c5c;
}

.style__gray-icon__GyU6u {
  background-color: #e0e0e0;
}

.style__status-on-track__u9kFN {
  height: 40px;
  width: 105px;
  background-color: #009933;
  border-radius: 0px;
  color: white !important;
  padding: 12px 10px 10px 10px;
  margin-top: -20px;
  position: absolute;
  margin-left: -50px;
}

.style__status-done__Lf7k3 {
  height: 40px;
  width: 105px;
  background-color: #666666;
  border-radius: 0px;
  color: white !important;
  padding: 12px 10px 10px 10px;
  margin-top: -20px;
  position: absolute;
  margin-left: -50px;
}

.style__status-planned__aUysT {
  height: 40px;
  width: 105px;
  background-color: #24a2eb;
  border-radius: 0px;
  color: white !important;
  padding: 12px 10px 10px 10px;
  position: absolute;
}

.style__status-at-risk__hDIJL {
  height: 40px;
  width: 105px;
  background-color: #c20c37;
  border-radius: 0px;
  color: white !important;
  padding: 12px 10px 10px 10px;
  margin-top: -20px;
  position: absolute;
  margin-left: -50px;
}

.style__label__C0Tw9 {
  padding: 4px 0px 0px 13px;
  font-size: 14px;
  color: #000000;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - 37px);
}