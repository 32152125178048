:export {
  successColor: #00bf64;
  warningColor: #e81a56;
}.focusStyles__focus__LMyvW:focus:not(.focus-visible) {
  outline: none;
}.focusStyles__focus__LMyvW.focus-visible {
  outline: 1px solid #6097d2 !important;
  z-index: 99;
}.focusStyles__input-focus__AjtKC:focus:not(.focus-visible) {
  outline: none;
}.focusStyles__input-focus__AjtKC.focus-visible {
  outline: 1px solid #6097d2;
}:export {
  focus: focusStyles__focus__LMyvW;
  input-focus: focusStyles__input-focus__AjtKC;
}
.style__link__viIPN {
  color: #666666;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
  padding: 3px;
  margin-left: -3px;
}
.style__link__viIPN:not(:last-of-type) {
  margin-right: 25px;
}

.style__link__viIPN:link,
.style__link__viIPN:visited {
  color: #666666;
}

.style__link__viIPN:hover,
.style__link__viIPN:focus {
  color: black;
  cursor: pointer;
}
.style__link__viIPN:hover .style__icon__ybHUP,
.style__link__viIPN:focus .style__icon__ybHUP {
  fill: black;
}

.style__link__viIPN:active {
  outline: none;
  background: #f2f2f2;
  border-radius: 3px;
}

.style__linkDisabled__isATO {
  color: #979797;
  text-align: center;
  text-decoration: none;
  cursor: default;
  font-size: 14px;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
  padding: 3px;
  margin-left: -3px;
}
.style__linkDisabled__isATO .style__icon__ybHUP {
  fill: #979797;
}
.style__linkDisabled__isATO:not(:last-of-type) {
  margin-right: 25px;
}

.style__linkDisabled__isATO:hover,
.style__linkDisabled__isATO:focus {
  cursor: default;
  text-decoration: none;
}

.style__linkDisabled__isATO:link,
.style__linkDisabled__isATO:visited {
  color: #979797;
  cursor: default;
}

.style__icon__ybHUP {
  height: 14px;
  width: 14px;
  flex-shrink: 0;
  fill: #666666;
  transition: fill 0.3s ease;
  margin-right: 12px;
}