.style__button__njXr8 {
  width: 100%;
  height: 30px;
  background-color: #eeeeee;
  border-radius: 3px;
  color: #444444;
  transition: background-color 0.2s ease-out;
  white-space: nowrap;
  font-family: inherit;
}
.style__button__njXr8:focus {
  outline-color: #6097d2;
}

.style__default__qYSoe {
  background: #eeeeee;
  color: #444444;
}
.style__default__qYSoe:hover {
  background: #e0e0e0;
}
.style__default__qYSoe:active {
  background: #c0c0c0;
}
.style__default__qYSoe:disabled {
  background: #f2f2f2;
}

.style__next__nuTkl {
  background: #eeeeee;
  border-radius: 2px;
  color: #ffffff;
}

.style__cancel__koKJK {
  background: #eeeeee;
  color: #444444;
}
.style__cancel__koKJK:focus {
  outline-color: #6097d2;
}
.style__cancel__koKJK:active {
  background: #c0c0c0;
}
.style__cancel__koKJK:hover {
  background: #e0e0e0;
}
.style__cancel__koKJK:disabled {
  background: #f2f2f2;
}

.style__danger__Bq-Zk {
  background: #cc2e3b;
  color: #ffffff;
}
.style__danger__Bq-Zk:focus {
  outline-color: #6097d2;
}
.style__danger__Bq-Zk:active {
  background: #a62833;
}
.style__danger__Bq-Zk:hover {
  background: #db656f;
}
.style__danger__Bq-Zk:disabled {
  background: #edbec2;
}

.style__success__TpCFV {
  background: #3576ba;
  color: #ffffff;
}
.style__success__TpCFV:focus {
  outline-color: #6097d2;
}
.style__success__TpCFV:active {
  background: #4c649d;
}
.style__success__TpCFV:hover {
  background: #6097d2;
}
.style__success__TpCFV:disabled {
  background: #b3ccea;
}

.style__transparent__gLrry {
  background: transparent;
  color: #111;
  border: none;
}
.style__transparent__gLrry:hover {
  background: #f2f2f2;
}

.style__button-medium__Gx5EZ {
  width: 132px;
}

.style__ghost-disabled__6ICOS {
  background: transparent;
  color: #646464;
  border: 1px solid #545454;
}
.style__ghost-disabled__6ICOS:disabled {
  opacity: 1;
}

.style__ghost-disabled-destructive__xH8g8 {
  background: transparent;
  color: #cc2e3b;
  border: 1px solid #cc2e3b;
}
.style__ghost-disabled-destructive__xH8g8:disabled {
  opacity: 1;
}

.style__ghost-disabled-primary__r3yCn {
  background: transparent;
  color: #3576ba;
  border: 1px solid #3576ba;
}
.style__ghost-disabled-primary__r3yCn:disabled {
  opacity: 1;
}

.style__link__BN-qz {
  width: auto;
  background: transparent;
  color: #0970be;
  border: none;
}

.style__unadorned__j19zB {
  color: #000000;
  border: none;
}
.style__unadorned__j19zB:focus {
  outline-color: #6097d2;
}
.style__unadorned__j19zB:hover {
  background: rgba(0, 0, 0, 0.05);
}

.style__unadornedCurrent__DZ8cX {
  width: auto;
  color: #000000;
  border: none;
  font-weight: bold;
}

.style__load__DKD06 {
  padding: 5px 24px 5px 24px;
  width: auto;
}

.style__queue__1tXUm {
  background: #eeeeee;
  color: #444444;
  padding: 5px 24px 5px 24px;
  width: auto;
}
.style__queue__1tXUm:focus {
  outline-color: #6097d2;
}
.style__queue__1tXUm:hover {
  background: #e0e0e0;
}
.style__queue__1tXUm:disabled {
  background: #f2f2f2;
}
.style__queue__1tXUm:active {
  background: #c0c0c0;
}

.style__upload__xVfXO {
  background: #c0c0c0;
  color: #ffffff;
  border: none;
}
.style__upload__xVfXO:hover {
  background: #adadad;
}

.style__contactSupportButton__AtMq- {
  height: 30px;
  border: 1px solid;
  color: #0073ec;
  border-radius: 2px;
  cursor: pointer;
  font-size: 13px;
  font-family: Roboto, Arial, Helvetica, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  width: 176px;
  background: #0073ec;
  border-color: #005ee0;
  color: #ffffff;
}
.style__contactSupportButton__AtMq- :hover {
  background: #005cbc;
}
.style__contactSupportButton__AtMq- :active {
  background: #0050a5;
}