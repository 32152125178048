.allOrNone__wrap__-bIqp {
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 0px 12px;
}

.allOrNone__sep__mNzV6 {
  display: inline-block;
  background-color: #e5e5e5;
  width: 1px;
  height: 16px;
  margin-bottom: -3px;
  color: #e4e4e4;
}

.allOrNone__button__r1JBW {
  display: inline-block;
  color: #000;
  padding: 0px 6px 0px 6px;
  margin-right: 6px;
  cursor: pointer;
  border-radius: 2px;
}
.allOrNone__button__r1JBW:first-of-type {
  margin-left: -6px;
}
.allOrNone__button__r1JBW:last-of-type {
  margin-left: 6px;
}