.style__avatar__hByt0 {
  position: relative;
  overflow: hidden;
}

.style__avatar-extra-small__ig4TA {
  height: 23px;
  width: 23px;
}

.style__avatar-medium-small__qnrkN {
  height: 36px;
  width: 36px;
}

.style__avatar-small__RuitE {
  height: 48px;
  width: 48px;
}

.style__avatar-medium__ynETW {
  height: 54px;
  width: 54px;
}

.style__overlay__XK6wc {
  height: 100%;
  width: 100%;
  position: absolute;
  background: #eeeeee;
  padding: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgb(28, 28, 28);
}

.style__image__ObVCz {
  width: 100%;
}

.style__round__bXpuN {
  border-radius: 50%;
}