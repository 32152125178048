* {
  box-sizing: border-box;
}
html, body {
  height: 100%;
}
body {
  font-size: 14px;
  font-family: GibsonRegular, Verdana, sans-serif;
}
.withHeader {
  bottom: 0px;
}
input, textarea {
  font-family: GibsonRegular, Verdana, sans-serif;
}
@media print {
  #pageHdr {
    display: none;
  }
}
.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}
.js-focus-visible :active:not(.focus-visible) {
  outline: none;
}
button {
  width: inherit;
  height: inherit;
  border: 0;
  line-height: normal;
  overflow: visible;
  padding: 0;
  color: rgb(28, 28, 28);
  background-color: transparent;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  -webkit-user-select: none; /* for button */
  -moz-user-select: none;
  -ms-user-select: none;
}
button[disabled] {
  opacity: 0.6;
  pointer-events: none;
}
button[data-pseudo-disabled=true] {
  opacity: 0.6;
  cursor: default;
}
button[data-pseudo-disabled=true]:hover {
  background: inherit;
}
button[data-pseudo-disabled=true]:focus {
  outline: none !important;
}
::-webkit-input-placeholder {
  font-size: 14px;
  font-family: GibsonRegular, Verdana, sans-serif;
}
:-moz-placeholder { /* Firefox 18- */
  font-size: 14px;
  font-family: GibsonRegular, Verdana, sans-serif;
}
::-moz-placeholder { /* Firefox 19+ */
  font-size: 14px;
  font-family: GibsonRegular, Verdana, sans-serif;
}
:-ms-input-placeholder {
  font-size: 14px;
  font-family: GibsonRegular, Verdana, sans-serif;
}
input[type=text], textarea {
  font-size: 14px;
  font-family: GibsonRegular, Verdana, sans-serif;
}
@media print {
  #time-reportHeaderGraph > div > div:last-child { /* incurred */
    background: none;
    border-top: 20px solid #92d4f4;
  }
}
@media print {
  #time-reportHeaderGraph > div > div:first-child, #utilization-reportHeaderGraph div > div:first-child { /* incurred */
    background: none;
    border-top: 20px solid #24a2eb;
  }
}
@media print {
  #utilization-reportHeaderGraph div > div:nth-of-type(2) { /* future scheduled */
    background: none;
    border-top: 20px solid #92d4f4;
  }
}
@media print {
  #budgets-reportHeaderGraph div > div:last-child div {
    background: none;
  }
  #budgets-reportHeaderGraph div > div:last-child div div[data-graph-type=incurred] {
    border-top: 20px solid #92d4f4;
  }
  #budgets-reportHeaderGraph div > div:last-child div div[data-graph-type=scheduled] {
    border-top: 20px solid #24a2eb;
  }
  #budgets-reportHeaderGraph div > div:last-child div div[data-graph-type=budget] {
    border-top: 6px solid #195e91;
  }
  #budgets-reportHeaderGraph div > div:last-child div div[data-graph-type=budget-over] {
    border-top: 6px solid #e81a56;
  }
}
.feed__container {
  background: none !important;
}
[data-popper-reference-hidden=true] {
  visibility: hidden;
  pointer-events: none;
}