.style__wrapper-open__JW4O- {
  position: absolute;
  left: 175px;
  border-radius: 4px;
  box-shadow: 0px 0.75px 5px rgba(0, 0, 0, 0.3);
  background: white;
  z-index: 200;
}
.style__wrapper-closed__Lv1H2 {
  padding: 0;
}

.style__arrow__cIZI- {
  width: 20px;
  height: 40px;
  top: 50%;
  left: -20px;
  margin-top: -20px;
  position: absolute;
  z-index: 100;
  overflow: hidden;
}
.style__arrow__cIZI-:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: white;
  top: 10px;
  left: 10px;
  -webkit-box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.3);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.style__bottom__qgLKW {
  transform: translateY(10px);
}
.style__bottom__qgLKW .style__arrow__cIZI- {
  top: -10px;
  transform: rotate(90deg);
}

.style__top__mHkcG {
  transform: translateX(-20px);
}
.style__top__mHkcG .style__arrow__cIZI- {
  margin-top: 50px;
  transform: rotate(270deg);
  left: 78.5% !important;
}
.style__top__mHkcG .style__arrow__cIZI-:after {
  background-color: #ffffff;
}

.style__top-left__BjAKG {
  transform: translateX(-24px);
}
.style__top-left__BjAKG .style__arrow__cIZI- {
  margin-top: 75px;
  left: 10% !important;
  transform: rotate(270deg);
}
.style__top-left__BjAKG .style__arrow__cIZI-:after {
  background-color: #ffffff;
}

.style__right__kF0Tt {
  transform: translateX(10px);
}
.style__right__kF0Tt .style__arrow__cIZI- {
  left: -20;
  top: 50%;
}

.style__left__gBVXT {
  transform: translateX(-10px);
}
.style__left__gBVXT .style__arrow__cIZI- {
  transform: rotate(180deg);
  top: 50%;
}