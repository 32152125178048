.style__type__5RqvA {
  width: 14px;
  height: 14px;
  flex-shrink: 0;
  border-radius: 2px;
  background-color: #f2f2f2;
}

.style__Confirmed__Fop3O {
  background-color: #75cdfe;
}

.style__Tentative__a6m0k {
  background-color: #c0c0c0;
}

.style__Internal__hL6DZ {
  background-color: #cd89f0;
}

.style__Leave__o-6lr {
  background-color: #ffa73b;
}